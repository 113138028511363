<template>
	<div>
		<a-descriptions bordered size="small">
			<template slot="title">
				<b>loan offer</b>
				<a-button type="primary" @click="loanWordVisible = true" style="margin-left: 20px">Download Template</a-button>
			</template>
			<a-descriptions-item :label="$t('table.loanInfo.addInfo.number')" :span="3">
				<a-input v-model="disburseInfo.loanOfferId" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.loanInfo.addInfo.file')" :span="3">
				<UploadPreviewFile :uploadFilePath.sync="disburseInfo.loanOfferFile" :folderPath="disburseInfo.loanId"></UploadPreviewFile>
			</a-descriptions-item>
		</a-descriptions>
		<a-tabs v-model="activeKey" :animated="true">
			<a-tab-pane v-for="(info, index) in disburseInfo.carInfos" :key="index" :tab="$t('table.applyInfo.tabsMenu.vehicle') + ' ' + (index + 1)">
				<disburse-info
					:carInfo="disburseInfo.carInfos[index]"
					:loanId="disburseInfo.loanId"
					:loanType="loanInfoData.loanInfo.loanType"
				></disburse-info>
			</a-tab-pane>
		</a-tabs>
		<a-descriptions
			v-if="loanInfoData.loanInfo.loanType !== 7"
			bordered
			:title="$t('table.loanInfo.addInfo.sitVis') + $t('page.optional')"
			size="small"
		>
			<a-descriptions-item :label="$t('table.loanInfo.addInfo.addrDe')" :span="1">
				<a-input v-model="disburseInfo.svAddressDetail" />
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.loanInfo.addInfo.mapLoc')" :span="2">
				<a @click="ShowAddress" v-if="IsShowAddress" style="margin: 0 10px; display: inline-block">
					{{ $t('table.applyInfo.applicationLabel.homeLatLog') }}
				</a>
				<a-button type="primary" @click="ShowMap">
					{{ $t('page.add') }}
				</a-button>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.loanInfo.addInfo.photo')" :span="3">
				<UploadPreviewFile :uploadFilePath.sync="disburseInfo.svPhotos" :folderPath="disburseInfo.loanId"></UploadPreviewFile>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.loanInfo.addInfo.sitVisRe')" :span="3">
				<UploadPreviewFile :uploadFilePath.sync="disburseInfo.svReport" :folderPath="disburseInfo.loanId"></UploadPreviewFile>
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions v-if="loanInfoData.loanInfo.loanType !== 7" bordered :title="$t('table.loanInfo.addInfo.additionalFiles')" size="small">
			<a-descriptions-item :label="$t('table.loanInfo.addInfo.secAgre')" :span="3">
				<UploadPreviewFile :uploadFilePath.sync="disburseInfo.afSecurityAgreements" :folderPath="disburseInfo.loanId"></UploadPreviewFile>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.loanInfo.addInfo.brs') + $t('page.optional')" :span="3">
				<UploadPreviewFile :uploadFilePath.sync="disburseInfo.afBrss" :folderPath="disburseInfo.loanId"></UploadPreviewFile>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.loanInfo.addInfo.postDatChe') + $t('page.optional')" :span="3">
				<UploadPreviewFile :uploadFilePath.sync="disburseInfo.afPostDatedCheques" :folderPath="disburseInfo.loanId"></UploadPreviewFile>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.loanInfo.addInfo.loanCalc')" :span="3">
				<UploadPreviewFile :uploadFilePath.sync="disburseInfo.afLoanCalculator" :folderPath="disburseInfo.loanId"></UploadPreviewFile>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.loanInfo.addInfo.letOfUnder') + $t('page.optional')" :span="3">
				<UploadPreviewFile :uploadFilePath.sync="disburseInfo.afLetterOfUndertakings" :folderPath="disburseInfo.loanId"></UploadPreviewFile>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.loanInfo.addInfo.letOfUnderRes') + $t('page.optional')" :span="3">
				<UploadPreviewFile :uploadFilePath.sync="disburseInfo.afLetterOfUndertakingResponses" :folderPath="disburseInfo.loanId"></UploadPreviewFile>
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions v-if="loanInfoData.loanInfo.loanType === 7" bordered title="Loan Calculator" size="small">
			<a-descriptions-item label="Loan Calculator">
				<UploadPreviewFile :uploadFilePath.sync="disburseInfo.afLoanCalculator" :folderPath="disburseInfo.loanId"> </UploadPreviewFile>
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions class="table-descriptions" :title="$t('table.applyInfo.applicationLabel.supDoc')" size="small">
			<a-descriptions-item :span="3">
				<a-table
					:columns="supCloums"
					size="small"
					:rowKey="(record, index) => index"
					:dataSource="disburseInfo.supplementFiles"
					:pagination="false"
					bordered
				>
					<span slot="fileName" slot-scope="text, obj">
						<a-input v-model="obj.filename" class="input_table" />
					</span>
					<span slot="file" slot-scope="obj">
						<a v-bind:href="$Util.getObsImageUrl($store.state.pageState.authObj, obj.file)" target="_blank">
							{{ !$Util.isEmpty(obj.file) ? obj.file.split('/').at(-1) : '' }}
						</a>
					</span>
					<span slot="fileRemark" slot-scope="text, obj">
						<a-input type="textarea" v-model="obj.note" :rows="4" :maxLength="300" />
					</span>
					<span slot="action" slot-scope="text, obj, index">
						<a @click="DelSup(index)">{{ $t('page.delete') }}</a>
					</span>
				</a-table>
			</a-descriptions-item>
			<a-descriptions-item>
				<UploadPreviewFile
					:uploadFilePath.sync="disburseInfo.supplementFiles"
					:folderPath="disburseInfo.loanId"
					:isPreviewFile="false"
				></UploadPreviewFile>
			</a-descriptions-item>
		</a-descriptions>
		<!-- 地图 -->
		<a-modal v-drag-modal v-model="MapModal.show" title="Map" :destroyOnClose="true" width="800px" @ok="handMapModalOk">
			<a-form-model layout="inline">
				<a-form-model-item>
					<a-input v-model="MapModal.name" />
				</a-form-model-item>
				<a-form-model-item>
					<a-button type="primary" @click="SearchMap" v-t.preserve="'operate.search2'"></a-button>
				</a-form-model-item>
			</a-form-model>
			<GmapMap :center="MapModal.center" :zoom="12" map-type-id="roadmap" style="width: 100%; height: 400px" ref="mapRef">
				<GmapMarker :position="MapModal.markpostion" :clickable="true" :draggable="true" @dragend="Dragend" ref="mark"> </GmapMarker>
			</GmapMap>
		</a-modal>
		<a-modal v-model="loanWordVisible" title="Download Template" @ok="exportWordDocx">
			<a-checkbox-group v-model="checkedWord" :options="optionsStatement" />
		</a-modal>
		<div class="btn_box">
			<a-button @click="SaveData" type="primary">{{ $t('operate.save') }}</a-button>
		</div>
	</div>
</template>
<script>
import { gmapApi } from 'vue2-google-maps'
import { apiApplyInfo, apiPostDisburseInfo, apiHasFillRegistrationNumber } from '@/api/loan'
import disburseInfo from '@/views/loanData/components/application/disburseInfo'
import { exportWordDocx } from '@/utils/exportWordDocx'
import { apiBranchManList } from '@/api/branch'
import moment from 'moment'
export default {
	data() {
		return {
			disburseInfo: {
				id: '',
				loanId: this.$route.query.id,
				loanOfferId: '',
				loanOfferFile: '',
				svAddressDetail: '',
				svMapLocation: '',
				svPhotos: [],
				svReport: '',
				afInsuranceEndorsementLetters: [],
				afSecurityAgreements: [],
				afBrss: [],
				afPostDatedCheques: '',
				afLoanCalculator: '',
				afLetterOfUndertakings: [],
				afLetterOfUndertakingResponses: [],
				supplementFiles: [],
				carInfos: []
			},
			MapModal: {
				show: false,
				name: '',
				center: { lat: -1.294403, lng: 36.817582 },
				markpostion: { lat: -1.294403, lng: 36.817582 }
			},
			map: null,
			IsShowAddress: false,
			centerlatlng: {},
			id: this.$route.query.id,
			activeKey: 0,
			endorsements: [{}],
			carInfoValue: {
				id: '',
				ntsaFile: '',
				trackingCertificate: '',
				carTrackingPin: '',
				photos: [],
				endorsements: [
					{
						endorsementLetters: [],
						expireDate: ''
					}
				],
				ntsa: {
					id: '',
					ntsaFile: '',
					make: '',
					model: '',
					year: '',
					registrationNumber: '',
					frameNumber: '',
					engineNo: '',
					belongs: []
				},
				logbook: {
					id: '',
					logbookFile: '',
					make: '',
					model: '',
					year: '',
					registrationNumber: '',
					frameNumber: '',
					engineNo: '',
					belongs: []
				}
			},
			loanInfoData: null,
			loanWordVisible: false,
			optionsStatement: [
				{ label: 'Loan Offer', value: 'loan' },
				{ label: 'Security Agreement', value: 'security' }
			],
			checkedWord: [],
			addrMap: {}
		}
	},
	components: {
		disburseInfo
	},
	computed: {
		supCloums() {
			return [
				{
					// 文件名称
					title: this.$t('table.applyInfo.applicationLabel.supCloums.fileName'),
					scopedSlots: { customRender: 'fileName' },
					align: 'center',
					width: 300
				},
				{
					// 文件
					title: this.$t('table.applyInfo.applicationLabel.supCloums.file'),
					scopedSlots: { customRender: 'file' },
					align: 'center'
				},
				{
					// 文件备注
					title: this.$t('table.applyInfo.applicationLabel.supCloums.fileRemark'),
					scopedSlots: { customRender: 'fileRemark' },
					align: 'center'
				},
				{
					// 操作
					title: this.$t('table.userFrom.columns.action'),
					scopedSlots: { customRender: 'action' },
					align: 'center',
					width: 100
				}
			]
		},
		// 将谷歌地图api 赋值给 vue.google
		google: gmapApi
	},
	created() {
		this.$nextTick(() => {
			Promise.all([apiApplyInfo(this.id), apiHasFillRegistrationNumber(this.id)]).then((res) => {
				this.loanInfoData = res[0]
				if (this.$Util.isEmpty(res[0].disburseInfo)) {
					for (let index = 0; index < res[0].loanCarInfos.length; index++) {
						res[1][index] ? delete this.carInfoValue.logbook : ''
						this.disburseInfo.carInfos.push(JSON.parse(JSON.stringify(this.carInfoValue)))
						this.disburseInfo.carInfos[index].hasFillRegistrationNumber = res[1][index]
					}
				} else {
					this.disburseInfo = res[0].disburseInfo
					if (this.$Util.isEmpty(this.disburseInfo.carInfos) || this.disburseInfo.carInfos === []) {
						this.disburseInfo.carInfos = []
						for (let index = 0; index < res[0].loanCarInfos.length; index++) {
							res[1][index] ? delete this.carInfoValue.logbook : ''
							this.disburseInfo.carInfos.push(JSON.parse(JSON.stringify(this.carInfoValue)))
							this.disburseInfo.carInfos[index].hasFillRegistrationNumber = res[1][index]
						}
					} else {
						for (let index = 0; index < this.disburseInfo.carInfos.length; index++) {
							this.disburseInfo.carInfos[index].hasFillRegistrationNumber = res[1][index]
						}
					}
				}
			})
			apiBranchManList().then((result) => {
				result.content.map((item) => {
					this.addrMap[item.id] = item.address
				})
			})
		})
	},
	methods: {
		moment: moment,
		exportWordDocx() {
			const basePrefix = process.env.NODE_ENV === 'development' ? `/bccms/` : `/`
			if (this.checkedWord.length === 0) {
				this.$message.error('Select Empty')
				return false
			}
			this.checkedWord.includes('loan')
				? exportWordDocx(
						`${basePrefix}loanOffer.docx`,
						{
							CaseID: this.loanInfoData.casePrefix + '-' + this.loanInfoData.caseId,
							FullName: this.loanInfoData.personalInfo.basicFullName,
							LoanType: this.$t(`table.applyInfo.financialLabel.loanMap.${this.loanInfoData.financeInfo.loanType}`),
							LoanTypeRNo: this.loanInfoData.loanCarInfos
								.map((item) => this.$loanMap.loanType[this.loanInfoData.loanInfo.loanType].prefixType + item.logbook.registrationNumber)
								.join(' ,'),
							Mobiles: this.loanInfoData.personalInfo.mobiles.map((item) => item.mobile).join(' ,'),
							BasicSex: this.loanInfoData.personalInfo.basicSex === 1 ? 'Sir' : 'Madam',
							LoanAmountDecimal: this.loanInfoData.loanInfo.applyAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.00',
							LoanAmount: this.loanInfoData.loanInfo.applyAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
							DownloadDate: moment().format('Do MMMM YYYY'),
							NationalID: this.loanInfoData.personalInfo.basicIdentityCard,
							PIN: this.loanInfoData.personalInfo.pin.pinNum,
							LoanInstallmentsNumber: this.loanInfoData.loanInfo.installmentsNumbers,
							LoanPeriod: (this.loanInfoData.loanInfo.interestRate * 100).toFixed(2) + '%',
							CommencingOnDate: moment().add(1, 'M').format('Do MMMM YYYY'),
							EndingOnDate: moment()
								.add(this.loanInfoData.loanInfo.installmentsNumbers + 1, 'M')
								.format('Do MMMM YYYY'),
							FirstMonthInstallment: this.loanInfoData.loanInfo.firstMonthRepay.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
							OtherMonthInstallment: this.loanInfoData.loanInfo.otherMonthRepay.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
							VehicleRegistrationNumber: this.loanInfoData.loanCarInfos.map((item) => item.logbook.registrationNumber).join(' ,'),
							Make: this.loanInfoData.loanCarInfos.map((item) => item.logbook.make).join(' /'),
							Model: this.loanInfoData.loanCarInfos.map((item) => item.logbook.model).join(' /'),
							EngineNO: this.loanInfoData.loanCarInfos.map((item) => item.logbook.engineNo).join(' /'),
							ChassisNO: this.loanInfoData.loanCarInfos.map((item) => item.logbook.frameNumber).join(' /'),
							NetAmount: this.loanInfoData.loanInfo.amountPayable.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
							AddrMap: this.addrMap[this.loanInfoData.storeId] ? this.addrMap[this.loanInfoData.storeId] : '',
							LPFRate: this.$loanMap.loanType[this.loanInfoData.loanInfo.loanType].defScale.loanProcessingFee,
							CIRate: this.$loanMap.loanType[this.loanInfoData.loanInfo.loanType].defScale.creditLifeInsuranceFee
								? `d.	Credit life will be charged at rate of ${
										this.$loanMap.loanType[this.loanInfoData.loanInfo.loanType].defScale.creditLifeInsuranceFee
								  } on the principal loan borrowed.`
								: '',
							LoanAmountList: this.$loanMap.loanType[this.loanInfoData.loanInfo.loanType].descriptionsItem.map((deItem) => {
								return {
									Name:
										this.$t(
											`table.applyInfo.loanApplyLabel.${
												this.$loanMap.loanType[this.loanInfoData.loanInfo.loanType].aliasItem.includes(deItem) ? deItem + 'Alias' : deItem
											}`
										) +
										(this.$loanMap.loanType[this.loanInfoData.loanInfo.loanType].defScale[deItem]
											? this.$loanMap.loanType[this.loanInfoData.loanInfo.loanType].defScale[deItem]
											: ''),
									Amount: this.loanInfoData.loanInfo[deItem].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
								}
							})
						},
						'Loan Offer.docx'
				  )
				: ''
			this.checkedWord.includes('security')
				? exportWordDocx(
						`${basePrefix}securityAgreement.docx`,
						{
							FullName: this.loanInfoData.personalInfo.basicFullName,
							DownloadDate: moment().format('Do MMMM YYYY'),
							LoanAmount: 'Kshs.' + this.loanInfoData.loanInfo.applyAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
							MotorVehicle: 'Motor vehicle',
							Make: this.loanInfoData.loanCarInfos.map((item) => item.logbook.make).join(' /'),
							YearOfManufacture: this.loanInfoData.loanCarInfos.map((item) => item.logbook.year).join(' /'),
							VehicleRegistrationNumber: this.loanInfoData.loanCarInfos.map((item) => item.logbook.registrationNumber).join(' /'),
							Model: this.loanInfoData.loanCarInfos.map((item) => item.logbook.model).join(' /'),
							EngineNO: this.loanInfoData.loanCarInfos.map((item) => item.logbook.engineNo).join(' /'),
							ChassisNO: this.loanInfoData.loanCarInfos.map((item) => item.logbook.frameNumber).join(' /')
						},
						'Security Agreement.docx'
				  )
				: ''
			this.loanWordVisible = false
			this.checkedWord = []
		},
		closeTab(targetKey) {
			this.$store.commit('tabs/delTab', targetKey)
			this.$store.commit('tabs/setActiveTab', '/home/loandata/loanlist')
		},
		// 提交表单
		SaveData() {
			let params = {
				id: this.disburseInfo.id,
				loanId: this.disburseInfo.loanId,
				loanOfferId: this.disburseInfo.loanOfferId,
				loanOfferFile: this.disburseInfo.loanOfferFile,
				svAddressDetail: this.disburseInfo.svAddressDetail,
				svMapLocation: this.disburseInfo.svMapLocation,
				svPhotos: this.disburseInfo.svPhotos,
				svReport: this.disburseInfo.svReport,
				afInsuranceEndorsementLetters: this.disburseInfo.afInsuranceEndorsementLetters,
				afSecurityAgreements: this.disburseInfo.afSecurityAgreements,
				afBrss: this.disburseInfo.afBrss,
				afPostDatedCheques: this.disburseInfo.afPostDatedCheques,
				afLoanCalculator: this.disburseInfo.afLoanCalculator,
				afLetterOfUndertakings: this.disburseInfo.afLetterOfUndertakings,
				afLetterOfUndertakingResponses: this.disburseInfo.afLetterOfUndertakingResponses,
				supplementFiles: this.disburseInfo.supplementFiles,
				carInfos: this.disburseInfo.carInfos
			}
			apiPostDisburseInfo(params)
				.then((res) => {
					this.$message.success('success')
					this.closeTab(this.$route.fullPath)
					this.$router.push('loanlist')
				})
				.catch((err) => {
					console.log(err)
				})
		},
		// 显示地图
		ShowMap() {
			this.MapModal.show = true
		},
		// 查询地图位置
		SearchMap() {
			this.map = this.$refs.mapRef.$mapObject
			let request = {
				query: this.MapModal.name,
				fields: ['name', 'geometry']
			}
			let service = new this.google.maps.places.PlacesService(this.map)
			service.findPlaceFromQuery(request, (results, status) => {
				if (status === this.google.maps.places.PlacesServiceStatus.OK && results) {
					let loaction = {
						lat: results[0].geometry.location.lat(),
						lng: results[0].geometry.location.lng()
					}
					this.MapModal.center = loaction
					this.MapModal.markpostion = loaction
				}
			})
		},
		// 提交地图信息
		handMapModalOk() {
			this.disburseInfo.svMapLocation = this.MapModal.markpostion.lat + ',' + this.MapModal.markpostion.lng
			console.log(this.disburseInfo.svMapLocation)
			this.centerlatlng = this.MapModal.markpostion
			this.MapModal.show = false
			this.IsShowAddress = true
		},
		// 定位移动后获取坐标
		Dragend(e) {
			this.MapModal.markpostion = {
				lat: Number(e.latLng.lat().toFixed(6)),
				lng: Number(e.latLng.lng().toFixed(6))
			}
		},
		// 显示当前坐标位置
		ShowAddress() {
			this.MapModal.center = this.centerlatlng
			this.MapModal.markpostion = this.centerlatlng
			this.MapModal.show = true
		},
		// 补充文件删除
		DelSup(idx) {
			this.disburseInfo.supplementFiles.splice(idx, 1)
		}
	}
}
</script>
<style scoped lang="less">
.line_span {
	display: inline-block;
	vertical-align: top;
	width: 200px;
	text-align: right;
	padding-right: 10px;
}
.ant-input,
.ant-select {
	width: 200px;
}
.file {
	left: 0;
	top: 0;
	width: 100%;
	bottom: 0;
	position: absolute;
	opacity: 0;
	cursor: pointer;
}
.file:hover {
	opacity: 0;
	text-decoration: none;
}
.input_box {
	width: 200px;
	vertical-align: top;
	display: inline-block;
	margin-right: 20px;
}
</style>
