<template>
	<!-- 贷款资料 -->
	<div>
		<div v-if="loanType !== 7">
			<div v-if="!carInfo.hasFillRegistrationNumber">
				<a-descriptions bordered :title="$t('table.applyInfo.vehicleLabel.logFile')" size="small">
					<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.logFile')">
						<UploadPreviewFile :uploadFilePath.sync="carInfo.logbook.logbookFile" :folderPath="loanId"></UploadPreviewFile>
					</a-descriptions-item>
				</a-descriptions>
				<a-descriptions bordered :title="$t('table.applyInfo.vehicleLabel.logFileInfo')" size="small">
					<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.vehicle')">
						<a-auto-complete v-model="carInfo.logbook.make" :data-source="vehicleMakeData" placeholder="Vehicle Make" />
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.model')">
						<a-auto-complete v-model="carInfo.logbook.model" :data-source="vehicleLogbookModelData" placeholder="Vehicle Model" />
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.year')">
						<!-- <a-select v-model="carInfo.logbook.year">
						<a-select-option value=""> Vehicle Years </a-select-option>
						<a-select-option :value="item.value" v-for="item in YearList" :key="item.value">
							{{ item.label }}
						</a-select-option>
					</a-select> -->
						<a-input v-model="carInfo.logbook.year" placeholder="Vehicle Years" />
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.license')">
						<a-input v-model="carInfo.logbook.registrationNumber" />
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.frame')">
						<a-input v-model="carInfo.logbook.frameNumber" />
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.engineNo')">
						<a-input v-model="carInfo.logbook.engineNo" />
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.attribution')" :span="3">
						<div class="box">
							<div class="list" v-for="(item, idx) in carInfo.logbook.belongs" :key="idx">
								<div class="span_labe">
									PIN:
									<a-input v-model="carInfo.logbook.belongs[idx].pin" />
								</div>
								<div class="span_labe">
									Name:
									<a-input v-model="carInfo.logbook.belongs[idx].name" />
								</div>
								<a-button type="primary" @click="delBelongs(idx)">{{ $t('page.delete') }}</a-button>
							</div>
							<div class="add-btn">
								<a-button type="primary" @click="AddBelongs(1)" :disabled="carInfo.logbook.belongs.length >= 2">
									{{ $t('page.add') }}
								</a-button>
							</div>
						</div>
					</a-descriptions-item>
				</a-descriptions>
			</div>
			<a-descriptions bordered :title="$t('table.applyInfo.vehicleLabel.logFileNest')" size="small">
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.logFileNest')">
					<UploadPreviewFile :uploadFilePath.sync="carInfo.ntsa.ntsaFile" :folderPath="loanId" :callbackFun="getNASA">
						<template v-slot:btn>
							<a-button @click="logFileNestBool = !logFileNestBool" style="margin-left: 10px">
								{{ $t('table.applyInfo.personalLabel.fillManual') }}
							</a-button>
						</template>
					</UploadPreviewFile>
				</a-descriptions-item>
			</a-descriptions>
			<a-descriptions v-show="logFileNestBool" bordered :title="$t('table.applyInfo.vehicleLabel.logFileInfoNTSA')" size="small">
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.vehicle')">
					<a-auto-complete v-model="carInfo.ntsa.make" :data-source="vehicleMakeData" placeholder="Vehicle Make" />
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.model')">
					<a-auto-complete v-model="carInfo.ntsa.model" :data-source="vehicleModelData" placeholder="Vehicle Model" />
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.myear')">
					<!-- <a-select v-model="carInfo.ntsa.year">
					<a-select-option value=""> Vehicle Years </a-select-option>
					<a-select-option :value="item.value" v-for="item in YearList" :key="item.value">
						{{ item.label }}
					</a-select-option>
				</a-select> -->
					<a-input v-model="carInfo.ntsa.year" placeholder="Vehicle Years" />
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.license')">
					<a-input v-model="carInfo.ntsa.registrationNumber" />
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.frame')">
					<a-input v-model="carInfo.ntsa.frameNumber" />
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.engineNo')">
					<a-input v-model="carInfo.ntsa.engineNo" />
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.attribution')" :span="3">
					<div class="box">
						<div class="list" v-for="(item, idx) in carInfo.ntsa.belongs" :key="idx">
							<div class="span_labe">
								{{ $t('table.applyInfo.vehicleLabel.pinCode') }}:
								<a-input v-model="carInfo.ntsa.belongs[idx].pin" />
							</div>
							<div class="span_labe">
								{{ $t('table.applyInfo.vehicleLabel.ownerName') }}:
								<a-input v-model="carInfo.ntsa.belongs[idx].name" />
							</div>
							<a-button type="primary" @click="loanApplyDelBelongs(idx)">{{ $t('page.delete') }}</a-button>
						</div>
						<div class="add-btn">
							<a-button type="primary" @click="AddBelongs(2)" :disabled="carInfo.ntsa.belongs.length >= 2">{{ $t('page.add') }}</a-button>
						</div>
					</div>
				</a-descriptions-item>
			</a-descriptions>
			<a-descriptions bordered size="small">
				<a-descriptions-item label="NTSA In-charge/Transfer" :span="3">
					<UploadPreviewFile :uploadFilePath.sync="carInfo.ntsaFile" :folderPath="loanId"></UploadPreviewFile>
				</a-descriptions-item>
			</a-descriptions>

			<a-descriptions bordered :title="`Tracking ${loanType === 3 ? '(optional)' : ''}`" size="small">
				<a-descriptions-item :label="$t('table.loanInfo.addInfo.traCer')" :span="3">
					<UploadPreviewFile :uploadFilePath.sync="carInfo.trackingCertificate" :folderPath="loanId" :isImgFile="true"></UploadPreviewFile>
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.loanInfo.addInfo.traPin')" :span="3">
					<UploadPreviewFile :uploadFilePath.sync="carInfo.carTrackingPin" :folderPath="loanId" :isImgFile="true"></UploadPreviewFile>
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.loanInfo.addInfo.photo') + $t('page.optional')" :span="3">
					<UploadPreviewFile :uploadFilePath.sync="carInfo.photos" :folderPath="loanId" :isImgFile="true"></UploadPreviewFile>
				</a-descriptions-item>
			</a-descriptions>
		</div>
		<p class="ant-descriptions-title">Insurance Endorsement</p>
		<a-tabs :defaultActiveKey="0" type="card" :animated="true" style="margin-top: 20px">
			<a-tab-pane :tab="'Insurance Endorsement ' + (carInfo.endorsements.length - index)" v-for="(endor, index) in carInfo.endorsements" :key="index">
				<a-descriptions bordered size="default">
					<a-descriptions-item label="Insurance Endorsement Letter (optional)" :span="3">
						<UploadPreviewFile :uploadFilePath.sync="endor.endorsementLetters" :folderPath="loanId"></UploadPreviewFile>
					</a-descriptions-item>
					<a-descriptions-item label="Expiry Date" :span="3">
						<a-date-picker :valueFormat="dateFormatVal" type="date" v-model="endor.expireDate" showTime />
					</a-descriptions-item>
				</a-descriptions>
			</a-tab-pane>
		</a-tabs>
		<!-- 车辆归属 -->
		<a-modal v-drag-modal v-model="belongsModel.show" :title="belongsModel.title" :destroyOnClose="true" width="800px" @ok="handModalOk">
			<a-form-model :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
				<a-form-model-item :label="$t('table.applyInfo.vehicleLabel.pinCode')">
					<a-input v-model="belongsModel.pin" />
				</a-form-model-item>
				<a-form-model-item :label="$t('table.applyInfo.vehicleLabel.ownerName')">
					<a-input v-model="belongsModel.name" />
				</a-form-model-item>
			</a-form-model>
		</a-modal>
	</div>
</template>
<script>
import { apiGetNTSA } from '@/api/loan'
export default {
	props: ['carInfo', 'loanId', 'loanType'],
	data() {
		return {
			columns1: [
				{
					// ID
					title: this.$t('table.loanInfo.addInfo.gpsCloums.id'),
					dataIndex: 'gpsId',
					align: 'center'
				},
				{
					// status
					title: this.$t('table.loanInfo.addInfo.gpsCloums.status'),
					dataIndex: 'status',
					align: 'center'
				},
				{
					// 操作
					title: this.$t('table.userFrom.columns.action'),
					scopedSlots: { customRender: 'action' },
					align: 'center'
				}
			],
			logFileNestBool: false,
			dateFormatVal: 'YYYY-MM-DD HH:mm:ss',
			belongsModel: {
				title: '',
				show: false,
				pin: '',
				name: '',
				type: 1
			}
		}
	},
	computed: {
		vehicleData() {
			const json = require('@/utils/car.json')
			let newkey = Object.keys(json).sort()
			let vehicleData = {} //创建一个新的对象，用于存放排好序的键值对
			for (var i = 0; i < newkey.length; i++) {
				vehicleData[newkey[i]] = json[newkey[i]]
			}
			return vehicleData
		},
		vehicleMakeData() {
			return Object.keys(this.vehicleData)
		},
		vehicleModelData() {
			let vehicleModelData = []
			if (!this.$Util.isEmpty(this.carInfo.ntsa.make) && this.vehicleData[this.carInfo.ntsa.make]) {
				vehicleModelData = this.vehicleData[this.carInfo.ntsa.make].map((item) => {
					return item.title
				})
				vehicleModelData.push('Other')
			}
			return vehicleModelData
		},
		vehicleLogbookModelData() {
			let vehicleLogbookModelData = []
			if (!this.$Util.isEmpty(this.carInfo.logbook.make) && this.vehicleData[this.carInfo.logbook.make]) {
				vehicleLogbookModelData = this.vehicleData[this.carInfo.logbook.make].map((item) => {
					return item.title
				})
				vehicleLogbookModelData.push('Other')
			}
			return vehicleLogbookModelData
		},
		YearList() {
			let arr = []
			const year = new Date().getFullYear()
			for (let i = 2001; i < year + 1; i++) {
				arr.push({
					label: i + '',
					value: i + ''
				})
			}
			return arr
		}
	},
	created() {
		this.$nextTick(() => {
			if (!this.$Util.isEmpty(this.carInfo.ntsa.id)) {
				this.logFileNestBool = true
			}
		})
	},
	methods: {
		getNASA(type) {
			console.log(type)
			apiGetNTSA({ filePath: type })
				.then((res) => {
					this.carInfo.ntsa = Object.assign(this.carInfo.ntsa, res)
					this.logFileNestBool = true
				})
				.catch(() => {
					this.logFileNestBool = false
					this.carInfo.ntsa = Object.assign(this.carInfo.ntsa, {
						engineNo: '',
						frameNumber: '',
						year: '',
						registrationNumber: '',
						belongs: [],
						model: '',
						make: ''
					})
				})
		},
		handModalOk() {
			const pinRege = /^[A-Za-z0-9]{11}$/
			if (!pinRege.test(this.belongsModel.pin)) {
				this.$message.error('pin error')
				return false
			} else if (!this.belongsModel.name) {
				this.$message.error('name error')
				return false
			}
			if (this.belongsModel.type == 1) {
				this.carInfo.logbook.belongs.push({
					pin: this.belongsModel.pin,
					name: this.belongsModel.name
				})
			} else if (this.belongsModel.type == 2) {
				this.carInfo.ntsa.belongs.push({
					pin: this.belongsModel.pin,
					name: this.belongsModel.name
				})
			}
			this.belongsModel.show = false
			this.belongsModel.pin = ''
			this.belongsModel.name = ''
		},
		AddBelongs(type) {
			this.belongsModel.title = this.$t('page.add')
			this.belongsModel.pin = ''
			this.belongsModel.name = ''
			this.belongsModel.show = true
			this.belongsModel.type = type
		},
		loanApplyDelBelongs(idx) {
			this.carInfo.ntsa.belongs.splice(idx, 1)
		},
		delBelongs(idx) {
			this.carInfo.logbook.belongs.splice(idx, 1)
		},
		deleteImg(idx) {
			this.carInfo.gpsInstallPics.splice(idx, 1)
		}
	}
}
</script>
<style scoped lang="less">
.ant-form-item {
	margin-bottom: 0;
}
.ant-input,
.ant-input-number,
.ant-select {
	width: 200px;
}
</style>
